.card1,
.card2,
.card3,
.card4 {
  position: absolute;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}

.card1 {
  min-width: 60ch;
  min-height: 60ch;
  width: 45vw;
  height: 45vw;
  max-width: 100ch;
  max-height: 100ch;
  background-image: url(./beach.svg);
}

.card2 {
  width: 25ch;
  height: 25ch;
  background-image: url(./sun.svg);
}

.card3 {
  opacity: 0.9;
  width: 25ch;
  height: 25ch;
  background-image: url(./clouds.svg);
}

.card4 {
  width: 25ch;
  height: 25ch;
  background-image: url(./bird.svg);
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
